<template>
  <div>
    <div
      v-for="(v, index) in volumes.filter((v) => v.volumeId === 0)"
      :key="index"
    >
      <table :class="loading ? 'caixa-carregando' : ''">
        <thead class="text-white">
          <tr>
            <th style="background-color: #002233 !important; font-size: 24px !important;">
              Caixa {{ volumes.length }} - {{ v.codigo }}
            </th>
          </tr>
        </thead>
      </table>
      <listagem-insumos-no-volume-linha-cabos
        :insumos="insumos"
        :volumes="volumes"
        :indexVol="index"
        :total-insumos="insumos.length"
        :loading="loading"
        v-on="$listeners"
        @biparInsumo="$emit('biparInsumo')"
      />
    </div>
  </div>
</template>

<script>
import ListagemInsumosNoVolumeLinhaCabos from "./ListagemInsumosNoVolumeLinhaCabos.vue";

export default {
  name: "ListagemCaixasLinhaCabos",
  components: {
    ListagemInsumosNoVolumeLinhaCabos,
  },
  props: {
    volumes: {
      type: Array,
      required: true,
    },
    insumos: {
      type: Array,
      required: true,
    },
    btnFecharCaixaLoading: {
      type: Boolean,
      required: true,
    },
    loading: { type: Boolean}

  },
  // computed: {
  //   listeners() {
  //     return {
  //       ...this.$listeners,
  //       click: this.onClick,
  //     };
  //   },
  // },
  // methods: {
  //   onClick(evt) {
  //     this.$emit("click", evt);
  //   },
  //   // tratarString(str) {
  //   //   let substituirStr = str.toLocaleLowerCase();
  //   //   let novaStr = substituirStr[0].toUpperCase() + substituirStr.substr(1);
  //   //   return novaStr;
  //   // },
  // },
};
</script>

<style scoped>
.cor-background-azul {
  background: #002233;
}
.number-modifier {
  border-style: none;
  background: #ff7d27;
}

.input-metragem {
  border-top: 0px;
  border-bottom: 0px;
  border-right: 0px;
  border-left: 0px;
  width: 80%;
  border-radius: 0px 0px 0px 0px;
}

.caixa-carregando {
  animation: animate 1s linear infinite;
}
@keyframes animate {
  0% {
    opacity: 0.8;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.8;
  }
}
</style>
